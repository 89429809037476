<template>
  <div class="create_wrapper custom_card">
    <!-- START:: CARD TITLE -->
    <div class="card-header">
      <h4 class="card-title">{{ $t("CRUD.Update.main_title") }}</h4>
    </div>
    <!-- END:: CARD TITLE -->
    <!-- START:: UPDATE FORM WRAPPER -->
    <form @submit.prevent="validateCreateForm">
      <div class="container">
        <div class="row justify-content-between">
          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="text"
                class="form-control"
                id="name_input"
                v-model.trim="updateData.project_name"
              />
              <label class="form-label">{{
                $t("settings.contactData.project_name")
              }}</label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="number"
                class="form-control"
                id="name_input"
                v-model.trim="updateData.phone"
              />
              <label class="form-label">
                {{ $t("settings.contactData.phone") }}
              </label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="email"
                class="form-control"
                id="name_input2"
                @input="checkIfInputIsEmpty"
                v-model.trim="updateData.email"
              />
              <label class="form-label">
                {{ $t("settings.contactData.email") }}
              </label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->
          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="textarea"
                class="form-control"
                id="name_input"
                v-model.trim="updateData.description_location"
              />
              <label class="form-label">
                {{ $t("settings.contactData.description_location") }}</label
              >
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->
          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="number"
                class="form-control"
                id="name_input2"
                @input="checkIfInputIsEmpty"
                v-model.trim="updateData.reach_free_delivery"
              />
              <label class="form-label"
                >{{ $t("settings.contactData.reach_free_delivery") }}
              </label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->
          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="number"
                class="form-control"
                id="name_input2"
                @input="checkIfInputIsEmpty"
                v-model.trim="updateData.shipping_price"
              />
              <label class="form-label">
                {{ $t("settings.contactData.shipping_price") }}
              </label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->
          <!-- START:: INPUT WRAPPER -->
          <!-- <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="number"
                class="form-control"
                id="name_input2"
                @input="checkIfInputIsEmpty"
                v-model.trim="updateData.distance_available_for_delivery"
              />
              <label class="form-label">
                Distance Available For Delivery
              </label>
            </div>
          </div> -->
          <!-- END:: INPUT WRAPPER -->
          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="number"
                class="form-control"
                id="name_input2"
                @input="checkIfInputIsEmpty"
                v-model.trim="updateData.total_product_for_discount_shipping"
              />
              <label class="form-label">
                {{
                  $t("settings.contactData.total_product_for_discount_shipping")
                }}
              </label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->
          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="number"
                class="form-control"
                id="name_input2"
                @input="checkIfInputIsEmpty"
                v-model.trim="updateData.vat_percentage"
              />
              <label class="form-label">
                {{ $t("settings.contactData.vat_percentage") }}
              </label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->
          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="number"
                class="form-control"
                id="name_input2"
                @input="checkIfInputIsEmpty"
                v-model.trim="updateData.limit_for_total_order_price"
              />
              <label class="form-label">
                {{ $t("settings.limit_for_total_order_price") }}
              </label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->
          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="number"
                class="form-control"
                id="name_input3"
                @input="checkIfInputIsEmpty"
                v-model.trim="updateData.min_price_to_buy_out_of_stock"
              />
              <label class="form-label">
                {{ $t("settings.min_price_to_buy_out_of_stock") }}
              </label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->
          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="number"
                class="form-control"
                id="name_input2"
                @input="checkIfInputIsEmpty"
                v-model.trim="updateData.remaining_time_for_out_of_stock"
              />
              <label class="form-label">
                {{ $t("settings.contactData.remaining_time_for_out_of_stock") }}
              </label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->
          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="number"
                class="form-control"
                id="name_input2"
                @input="checkIfInputIsEmpty"
                v-model.trim="updateData.available_distance"
              />
              <label class="form-label">
                {{ $t("settings.contactData.available_distance") }}</label
              >
            </div>
          </div>
          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="number"
                class="form-control"
                id="name_input2"
                @input="checkIfInputIsEmpty"
                v-model.trim="updateData.minutes_for_prevent_order"
              />
              <label class="form-label">
                {{ `${$t("minutes_for_prevent_order")} (  ${$t("mins")} )` }}
              </label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->
          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="text"
                class="form-control"
                id="name_input3"
                @input="checkIfInputIsEmpty"
                v-model.trim="updateData.whatsapp"
              />
              <label class="form-label">
                {{ $t("settings.contactData.whatsapp") }}
              </label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="url"
                class="form-control"
                id="name_input4"
                @input="checkIfInputIsEmpty"
                v-model.trim="updateData.facebook"
              />
              <label class="form-label">
                {{ $t("settings.contactData.facebook") }}
              </label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="url"
                class="form-control"
                id="name_input5"
                @input="checkIfInputIsEmpty"
                v-model.trim="updateData.twitter"
              />
              <label class="form-label">
                {{ $t("settings.contactData.twitter") }}
              </label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="url"
                class="form-control"
                id="name_input6"
                @input="checkIfInputIsEmpty"
                v-model.trim="updateData.youtube"
              />
              <label class="form-label">
                {{ $t("settings.contactData.youtube") }}
              </label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="url"
                class="form-control"
                id="name_input8"
                @input="checkIfInputIsEmpty"
                v-model.trim="updateData.instagram"
              />
              <label class="form-label">
                {{ $t("settings.contactData.instagram") }}
              </label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="url"
                class="form-control"
                id="name_input8"
                @input="checkIfInputIsEmpty"
                v-model.trim="updateData.pinterest"
              />
              <label class="form-label">
                {{ $t("settings.contactData.pinterest") }}
              </label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="url"
                class="form-control"
                id="name_input8"
                @input="checkIfInputIsEmpty"
                v-model.trim="updateData.tik_tok"
              />
              <label class="form-label">
                {{ $t("settings.contactData.tiktok") }}</label
              >
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="url"
                class="form-control"
                id="name_input8"
                @input="checkIfInputIsEmpty"
                v-model.trim="updateData.snapchat"
              />
              <label class="form-label">
                {{ $t("settings.contactData.snapchat") }}
              </label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="url"
                class="form-control"
                id="name_input8"
                @input="checkIfInputIsEmpty"
                v-model.trim="updateData.ios_link"
              />
              <label class="form-label">
                {{ $t("settings.contactData.ios_link") }}</label
              >
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="textarea"
                class="form-control"
                id="name_input"
                v-model.trim="updateData.android_link"
              />
              <label class="form-label">{{
                $t("settings.contactData.android_link")
              }}</label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->
          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-6 py-0">
            <div class="input_wrapper top_label">
              <input
                type="textarea"
                class="form-control"
                id="name_input"
                v-model.trim="updateData.magazine_url"
              />
              <label class="form-label">{{ $t("magazine_url") }}</label>
            </div>
          </div>
          <!-- END:: INPUT WRAPPER -->
        </div>
      </div>

      <div class="buttons_wrapper">
        <!-- START:: BUTTON -->
        <button
          class="button_style_1"
          type="button"
          @click="validateCreateForm()"
          :class="btnIsLoading ? 'disabled' : ''"
        >
          {{ $t("forms.submit") }}
          <span class="btn_loader" v-if="btnIsLoading"></span>
        </button>
        <!-- END:: BUTTON -->
      </div>
    </form>
    <!-- END:: UPDATE FORM WRAPPER -->
  </div>
</template>

<script>
export default {
  name: "Update",
  data() {
    return {
      // START:: BUTTON LOADER HANDLING DATA
      btnIsLoading: false,
      // END:: BUTTON LOADER HANDLING DATA
      // START:: CREATE DATA
      updateData: {
        email: null,
        phone: null,
        project_name: null,
        facebook: null,
        instagram: null,
        ios_link: null,
        pinterest: null,
        android_link: null,
        snapchat: null,
        lng: null,
        limit_for_total_order_price: null,
        description_location: null,
        lat: null,
        twitter: null,
        tik_tok: null,
        whatsapp: null,
        youtube: null,
        reach_free_delivery: null,
        shipping_price: null,
        distance_available_for_delivery: null,
        total_product_for_discount_shipping: null,
        vat_percentage: null,
        remaining_time_for_out_of_stock: null,
        available_distance: null,
        minutes_for_prevent_order: null,
        magazine_url: null,
        min_price_to_buy_out_of_stock: null,
      },
      // START:: MAP
      center: {
        lat: null,
        lng: null,
      },

      markers: [],
      coordinates_to_edit: { lat: 0, lng: 0 },
    };
  },
  methods: {
    updateCoordinates(location) {
      this.updateData = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
    },

    clickMethod(m) {
      this.center = m.position;
    },
    // ============== Get User Location
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.markers = [
            {
              position: {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              },
            },
          ];
          this.center = this.markers[0].position;
          this.updateData.lat = this.center.lat;
          this.updateData.lng = this.center.lng;
        });
      }
    },

    // START:: G-MAP GET ADDRESS
    getAddressAr() {
      var self = this;
      var input = document.getElementById("autocomplete_ar");
      var searchBox = new google.maps.places.SearchBox(input);
      searchBox.addListener("places_changed", function () {
        var places = searchBox.getPlaces();
        if (places.length == 0) {
          return;
        }
        var bounds = new google.maps.LatLngBounds();
        places.forEach(function (place) {
          console.log(place);
          bounds.extend(place.geometry.location);
          place.geometry.location.lat();
          place.geometry.location.lng();
          place.formatted_address;
          self.updateData.address = place.formatted_address;
          self.center.lat = place.geometry.location.lat();
          self.center.lng = place.geometry.location.lng();
          self.updateData.lat = place.geometry.location.lat();
          self.updateData.lng = place.geometry.location.lng();
          self.markers[0].position.lat = place.geometry.location.lat();
          self.markers[0].position.lng = place.geometry.location.lat();
        });
      });
    },
    // START:: G-MAP GET ADDRESS
    // START:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATED PLACEHOLDER INPUTS)
    checkIfInputIsEmpty(e) {
      let inputElement = e.currentTarget;
      if (inputElement.value.length > 0) {
        inputElement.classList.add("not_empty");
      } else {
        inputElement.classList.remove("not_empty");
      }
    },
    // END:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATED PLACEHOLDER INPUTS)

    //START:: GET DATA
    getData() {
      this.$axios({
        method: "GET",
        url: "settings",
      }).then((res) => {
        this.updateData = res.data.data;
      });
    },
    //END:: GET DATA

    // START:: VALIDATE CREATE FORM
    validateCreateForm() {
      console.log("test");
      this.btnIsLoading = true;
      const data = new FormData();
      data.append("android_link", this.updateData.android_link);
      data.append("email", this.updateData.email);
      data.append("facebook", this.updateData.facebook);
      data.append("instagram", this.updateData.instagram);
      data.append("ios_link", this.updateData.ios_link);
      data.append("phone", this.updateData.phone);
      data.append("pinterest", this.updateData.pinterest);
      data.append("project_name", this.updateData.project_name);
      data.append("description_location", this.updateData.description_location);
      data.append("lng", +this.updateData.lng);
      data.append("lat", +this.updateData.lat);
      data.append("twitter", this.updateData.twitter);
      data.append("tik_tok", this.updateData.tik_tok);
      data.append("whatsapp", this.updateData.whatsapp);
      data.append("youtube", this.updateData.youtube);
      data.append("reach_free_delivery", this.updateData.reach_free_delivery);
      data.append("shipping_price", this.updateData.shipping_price);
      // data.append("distance_available_for_delivery",this.updateData.distance_available_for_delivery)
      data.append(
        "total_product_for_discount_shipping",
        this.updateData.total_product_for_discount_shipping
      );
      data.append(
        "min_price_to_buy_out_of_stock",
        this.updateData.min_price_to_buy_out_of_stock
      );
      data.append(
        "limit_for_total_order_price",
        this.updateData.limit_for_total_order_price
      );
      data.append("vat_percentage", this.updateData.vat_percentage);
      data.append("magazine_url", this.updateData.magazine_url || "");
      data.append(
        "minutes_for_prevent_order",
        this.updateData.minutes_for_prevent_order || ""
      );
      data.append(
        "remaining_time_for_out_of_stock",
        this.updateData.remaining_time_for_out_of_stock
      );
      data.append("available_distance", this.updateData.available_distance);
      this.$axios({
        method: "post",
        url: "settings",
        data: data,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("editSuccess"),
            position: "bottomRight",
          });
          this.btnIsLoading = false;
          this.$router.push({ path: "/settings" });
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
          this.btnIsLoading = false;
        });
    },
    // END:: VALIDATE CREATE FORM
  },
  created() {
    this.getLocation();
    this.getData();
  },
};
</script>
